import { Vue, Component } from 'vue-property-decorator';
import Utils from '@/utils';
import { isServer } from '@/configs/index';

export default {
    /* beforeRouteUpdate(to: any, from: any, next: any) {
        const self: any = this;
        // 上报不同页面的进入事件
        self.pageAction(to, from.name);
    }, */
    /* beforeRouteEnter(to: any, from: any, next: any) {
        // 非详情页返回的，都设置到顶部
        if (to.name.indexOf('List') > -1 && from.name.indexOf('Detail') === -1) {
            if (!isServer && window) {
                document.documentElement.scrollTop = 0
            }
        }
        next()
    }, */
    beforeRouteLeave(to: any, from: any, next: any) {
        const self: any = this;
        // 当前是详情页
        if (from.name.indexOf('Detail') > -1) {
            // 进入的是列表页面
            if (to.name.indexOf('List') > -1) {
                to.meta.keepAlive = true
            }
        }
        // 当前是列表页面
        if (from.name.indexOf('List') > -1) {
            // 进入的是详情页面
            if (to.name.indexOf('Detail') > -1) {
                from.meta.keepAlive = true
            } else {
                from.meta.keepAlive = false
                self.$destroy()
            }
        }
        console.log('Leave', to)
        next()
    }
}