

















/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "Mcoupon"
})
export default class Mcoupon extends Vue {
  @Prop() private info!: any;
  private formatTime(time: number | string): string {
    const self: any = this;
    return self.utils.formatTime(new Date(Number(time) * 1000), "Y/M/D");
  }
  get getInfo(): any {
    let info: any = this.info;
    const ops: any = {};
    // 判断是否有award字段
    if (info.award && info.award.coupon_fee_info) {
      ops.type = info.award.coupon_fee_info.coupon_fee_type;
      ops.money =
        Number(ops.type) === 1
          ? info.award.coupon_fee_info.reduce_cost
          : info.award.coupon_fee_info.discount / 10;
    } else {
      ops.type = info.name.indexOf("折") !== -1 ? 2 : 1; // 通过名字判断
      ops.money = 0;
    }
    info = Object.assign({}, info, ops);
    return info;
  }
}
