














/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import mixins from "@/mixin/index.ts";
import utils from "@/utils";
@Component({
  name: "AppInstallBtn",
  mixins: [mixins]
})
export default class AppInstallBtn extends Vue {
  @Prop({ default: () => ["下载"] }) private texts!: any[];
  @Prop({ default: () => ["#fc5b23"] }) private bgColors!: any[];
  @Prop() private colors!: any[];
  @Prop() private appInfo!: object;
  private downloadUrl: string = "";
  private downloadName: string = "";

  get btnStyle() {
    // 判断按钮状态来显示不同的颜色
    if (true) {
      return `background-color: ${this.bgColors[0]};`;
    }
  }
  get getTxt(): string {
    return this.texts[0];
  }
  private async installGame() {
    const self: any = this;
    const refs: any = self.$refs;
    const options = self.assembleDownloadParams();
    self.sendAction("Click_download", options);
    if (self.appInfo.downloadUrl) {
      window.open(self.appInfo.downloadUrl)
    } else {
      this.$store
        .dispatch("fetchAppUrl", {
          context: this,
          params: {
            package_name: self.appInfo.package_name || self.appInfo.pkg,
            game_id: self.appInfo.id,
            timestamp: new Date().getTime(),
            auth_time: 6000
          }
        })
        .then((res: any) => {
          const downloadUrl: string = res.value || "";
          self.sendAction("Click_download_succeed", options);
          // window.open(downloadUrl)
          // window.location.href = downloadUrl;
          self.downloadUrl = downloadUrl + '&fsuffix=apk';  // 拼接cdn服务商提供的后缀名参数fsuffix=apk
          // 判断是否已有后缀名
          /* if (downloadUrl.indexOf(".apk") === -1) {
            self.downloadName =
              self.utils.getUrlParam("fname", downloadUrl) + ".apk";
          } */
          /* self.utils.getBlob(self.downloadUrl).then((blob: any) => {
            console.log(blob);
            self.utils.saveAs(blob, self.downloadName);
          }); */
          self.$nextTick(() => {
            refs.download.click();
          })
        })
        .catch((err: any) => {
          console.log(err);
          if (err.code) {
            self.sendAction("Click_download_codeFail", {
              code: err.code,
              ...options
            });
          } else {
            self.sendAction("Click_download_netFail", {
              msg: err,
              ...options
            });
          }
          self.$toast.show(err.message || "网络错误，请重试！");
        });
    }
  }
  private assembleDownloadParams(): any {
    const self: any = this;
    return {
      request_id: new Date().getTime(), // 请求ID，唯一标识每一次请求
      apkname: self.appInfo.package_name || self.appInfo.pkg, // apk包名
      appversion: self.appInfo.version_name || self.appInfo.version_code, // 游戏版本
      appid: self.appInfo.id, // 游戏id
      app_id: self.appInfo.id, // 游戏id
      appname: self.appInfo.name || self.appInfo.title, // 游戏名称
      _page_: self.appInfo.data_source, // 安装来源页==数据来源
      pos_ver: self.appInfo.py, // 页面位置,判断数组的位置
      pos_hor: self.appInfo.px, // 横向位置，
      // rank_id: self.appInfo.rank_id, // 所在榜单ID
      rank_pos: self.appInfo.px, // 在榜单中的位置
      block_id: self.appInfo.block_id, // 榜单块的id
      block_name: self.appInfo.block_name, // 榜单块的名字
      source_page: utils.getUrlParam("source_page"), // 详情页向上回溯一层
      source_pos_ver: utils.getUrlParam("source_pos_ver"),
      source_pos_hor: utils.getUrlParam("source_pos_hor"),
      source_block_name: utils.getUrlParam("source_block_name"),
      source_block_id: utils.getUrlParam("source_block_id"),
      rank_id: utils.getUrlParam('source_rank_id'), // 所在榜单ID
    };
  }
}
