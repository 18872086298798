















/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";

import ImgBlock from "@/components/widget/img-block.vue";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "NewsList",
  components: { ImgBlock }
})
export default class NewsList extends Vue {
  @Prop() private info!: any[];
  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      title: info.title,
      read: info.read_count,
      from: info.source_name,
      imgsrc: info.cover_mid_img || (info.head_image && JSON.parse(info.head_image)[0]),
      btype: 'news'
    });
    return info;
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    self.$emit("clickBlock", self.getInfo, type, item);
  }
}
