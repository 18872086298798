


























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getScrollParent, getCurrentDistance } from "@/utils";
// var spinnerMapping = {
//   bubbles: 'loading-bubbles',
//   circles: 'loading-circles',
//   default: 'loading-default',
//   spiral: 'loading-spiral',
//   waveDots: 'loading-wave-dots',
// };
@Component({
  name: "Loadmore"
})
export default class Loadmore extends Vue {
  @Prop({ default: 10 }) private distance!: number;
  @Prop() private onInfinite!: any;
  @Prop({ default: "bottom" }) private direction!: string;
  @Prop({ default: false }) private loadMore!: boolean;
  @Prop({ default: "" }) private loadStatus!: string;
  private scrollParent: any = null;
  private scrollHandler: any = null;
  private isLoading: boolean = false;
  private noLoading: boolean = false;
  private ready: boolean = false;
  private listBoxHei: number = 0;
  private showLoad: boolean = false;  // 控制重置状态是否显示loading

  private mounted() {
    const self: any = this;
    self.$nextTick(() => {
      self.scrollParent = getScrollParent(self.$el); // 查询最近的可以滚动容器，找不到就找window
      self.scrollHandler = () => {
        // scrollHandlerOriginal
        if (!self.isLoading && self.ready) {
          self.tryLoad();
        }
      };
      // setTimeout(this.scrollHandler, 1);
      // 初始化是否需要绑定
      if (self.loadMore) {
        self.scrollParent.addEventListener("scroll", self.scrollHandler);
      }
    });
  }
  private tryLoad() {
    const refs: any = (this as any).$refs;
    const currentDistance = getCurrentDistance(
      this.scrollParent,
      refs["loadMore-warp"],
      this.direction
    );
    // 已经触发
    if (currentDistance <= this.distance) {
      this.isLoading = true;
      this.onInfinite.call();
    } else {
      this.isLoading = false;
    }
  }
  private destroyed() {
    const self: any = this;
    if (self.scrollParent) {
      self.scrollParent.removeEventListener("scroll", self.scrollHandler);
    }
  }
  private activated(): void {
    // 防止使用keep-alive的移除
    const self: any = this;
    // 有滚动对象，并且是还有更多
    if (self.scrollParent && self.isLoading) {
      self.scrollParent.addEventListener("scroll", self.scrollHandler);
    }
  }
  private deactivated(): void {
    const self: any = this;
    // 只有绑定了的才需要销毁
    // if (self.loadMore) {
    if (self.scrollParent) {
      self.scrollParent.removeEventListener("scroll", self.scrollHandler);
    }
    // }
  }
  @Watch("loadStatus")
  private onChangeLoadStatus(val: string, old: string) {
    const self: any = this;
    let index = -1;
    ["ready", "loaded", "complete", "reset", "end"].map((it, idx) => {
      if (val.indexOf(it) > -1) {
        index = idx;
      }
    });
    switch (index) {
      case 0: // 初始化完毕
        self.ready = true;
        break;
      case 1: // 本次加载完成
        self.isLoading = false;
        break;
      case 2: // 没有更多了
        self.isLoading = false;
        self.noLoading = true;
        if (self.scrollParent) {
          self.scrollParent.removeEventListener("scroll", self.scrollHandler);
        }
        break;
      case 3: // 重置
        self.showLoad = true;
        self.isLoading = false;
        self.ready = true;
        if (self.scrollParent) {
          self.scrollParent.addEventListener("scroll", self.scrollHandler);
        }
        break;
      case 4: // 没有数据
        self.isLoading = false;
        self.noLoading = false;
        if (self.scrollParent) {
          self.scrollParent.removeEventListener("scroll", self.scrollHandler);
        }
        break;
      default:
        break;
    }
  }
}
