


























/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import InstallBtn from "@/components/InstallBtn.vue";
import ImgBlock from "@/components/widget/img-block.vue";
import { toRem } from "@/utils";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "AppBlockSmall",
  components: { InstallBtn, ImgBlock }
})
export default class AppBlockSmall extends Vue {
  @Prop() private info!: any[];
  @Prop({ default: () => [0] }) private margin!: any[]; // left/bottom的间距
  @Prop({ default: toRem(180) }) private width!: any; // 当前块的宽度，不包括margin padding
  @Prop({ default: true }) private lazyImg!: boolean; // 当前块的图片是否懒加载

  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      title: info.name,
      size: info.size,
      download: info.size ? true : false, // 是否显示下载
      imgsrc: info.icon || info.img_url,
      btype: 'game'
    });
    return info;
  }

  get getBlockStyle(): string {
    const len: number = this.margin.length;
    let margin: any[] = Array(4).fill(0);
    switch (len) {
      case 4:
        margin = this.margin;
        break;
      case 1:
        margin.fill(this.margin[0]);
        break;
      case 2:
        margin = [
          this.margin[0],
          this.margin[1],
          this.margin[0],
          this.margin[1]
        ];
        break;
      case 3:
        margin = [
          this.margin[0],
          this.margin[1],
          this.margin[2],
          this.margin[1]
        ];
        break;
      default:
        break;
    }
    return `margin: ${toRem(margin[0])} ${toRem(margin[1])} ${toRem(
      margin[2]
    )} ${toRem(margin[3])}`;
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    if (self.getInfo.downloadUrl) {
      return
    }
    self.$emit("clickBlock", self.info, type, item);
  }
}
