import { render, staticRenderFns } from "./Block-box.vue?vue&type=template&id=c6fe8a92&"
import script from "./Block-box.vue?vue&type=script&lang=ts&"
export * from "./Block-box.vue?vue&type=script&lang=ts&"
import style0 from "./Block-box.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports