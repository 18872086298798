






















/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import ImgBlock from "@/components/widget/img-block.vue";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "AppBlockCard",
  components: { ImgBlock }
})
export default class AppBlockCard extends Vue {
  @Prop() private info!: object;
  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      desc: info.description,
      name: info.app_name,
      grade: info.avg_score,
      imgsrc: info.cover_big_img || info.cover_vertical_img,
      btype: 'news'
    });
    return info;
  }
  get getSmallIcon(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      imgsrc: info.app_icon
    });
    return info;
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    self.$emit("clickBlock", self.getInfo, type, item);
  }
}
