


















/* tslint:disable:no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImgBlock from '@/components/widget/img-block.vue';
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'AppBlockCardSmall',
  components: { ImgBlock },
})
export default class AppBlockCardSmall extends Vue {
  @Prop() private info!: object;
  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      comment: info.evaluate.comment || info.recommend_desc,
      grade: info.avg_score,
      author: info.publisher,
      imgsrc: info.show_image,
      btype: 'game'
    });
    return info
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    self.$emit("clickBlock", self.getInfo, type, item);
  }
}
