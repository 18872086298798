














/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import ImgBlock from "@/components/widget/img-block.vue";
import { toRem } from "@/utils";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "ImgBlockNormal",
  components: { ImgBlock }
})
export default class ImgBlockNormal extends Vue {
  // 初始数据可以直接声明为实例的属性
  @Prop() private info!: object;
  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      imgsrc: info.img_url || info.cover_vertical_img || info.image,
      title: info.title || info.description
    });
    return info;
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    self.$emit("clickBlock", self.info, type, item);
  }
}
