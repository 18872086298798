






















/* tslint:disable:no-console */
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { blockMap } from "@/configs";
import Swiper from "swiper";
import mixins from "@/mixin/index.ts";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "MSwiper",
  mixins: [mixins]
})
export default class MSwiper extends Vue {
  @Prop() private block!: any;
  @Prop({ default: 0 }) private py!: number;
  @Prop({ default: 4000 }) private auto!: number;
  @Prop({ default: 300 }) private speed!: number;
  private mySwiper: any = null;

  get list(): any[] {
    const self: any = this;
    return (this.block.data || []).map((it: any, idx: number) => {
      it.px = idx;
      it.py = self.py;
      it.data_source = self.block.data_source; // 数据来源
      it.rank_id = self.block.rank_id; // 所在榜单ID
      it.block_id = self.block.id; // 榜单块的id
      it.block_name = self.block.name; // 榜单块的名字
      it.block_type = self.block.type; // 榜单块的类型
      it.blockLink = blockMap.Swiper[0].blockLink; // 榜单块的类型
      return it;
    });
  }
  private mounted(): void {
    this.swipperInit();
  }
  private swipperInit(): void {
    const self: any = this;
    this.$nextTick(() => {
      // 轮播
      self.mySwiper = new Swiper(".swiper-container", {
        slidesPerView: 1,
        spaceBetween: 6,
        loop: true,
        // loopAdditionalSlides: 1,
        autoplay: {
          delay: 5000,
          // disableOnInteraction: false
        },
        // autoHeight: true,
        observer: true,
        on: {
          slideChangeTransitionEnd() {
            const that: any = this;
            // 切换到了倒数第二个结束，则切换为第一个开始
            if (that.activeIndex === that.slidesGrid.length - 2) {
              that.slideToLoop(-1, 10, true);
              that.activeIndex = 1; // 修改当前的切换指针为1
            }
          }
        }
      });
    });
  }
  private clickBlock(eve: any): void {
    const self: any = this;
    const target = eve.target;
    if (target.nodeName === "A") {
      const li = target.parentNode;
      const px = Number(li.getAttribute("px")) || 0;
      const data = self.block[px] || self.block.data[px];
      const options = self.assembleParams(data, 'click');
      self.sendAction("click_banner", options);
      // 判断是进游戏详情
      if (data.downloadUrl) {
        const a = document.createElement('a')
        a.href = data.downloadUrl;  // 拼接cdn服务商提供的后缀名参数fsuffix=apk
        a.download = data.downloadName
        self.$nextTick(() => {
          a.click();
        })
        return
      }
      if (data.type === "app") {
        self.toPage(data.blockLink, options);
      } else if (data.type === 'activity') {
        // 活动
        this.$store
          .dispatch("fetchActivityDetail", {
            context: this,
            type: data.type,
            id: data.content_id,
            params: {}
          })
          .then(res => {
            if (res.value) {
              window.location.href = res.value.html_url;
            } else {
              self.$toast.show(res.message);
            }
          })
          .catch(err => {
            console.log(err);
            self.$toast.show(err.message);
          });
      } else {
        self.$toast.show('活动类型不支持！');
      }
    }
  }
}
