









import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "Stars"
})
export default class Stars extends Vue {
  @Prop() private star!: number;
  @Prop({default: false}) private noVal!: boolean;

  get starPlan(): string {
    const self: any = this;
    // 1-10
    return `width: ${Number(self.star) * 10}%`;
  }
}
