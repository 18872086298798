import { pageInfo } from '../configs'
const TARGET_NODE = process.env.VUE_ENV === "server";

function getTdk(vm: any) {
  const tdk = vm.tdk || vm.$options.tdk;
  if (tdk) {
    return tdk(vm);
  }
}

function updateTdk(vm: any, vdom: any) {
  const tdk = getTdk(vm);
  vdom.title = pageInfo.Title + (tdk && tdk.title) || '';
  vdom.description = (tdk && tdk.description) || pageInfo.Description;
  vdom.keywords = (tdk && tdk.keywords) || pageInfo.Keyword;
  // for append more meta or link seo need
  vdom.ssrHeadAddInfo = (tdk && tdk.ssrHeadAddInfo) || "";
}

function serverUpdateTdk(self: any) {
  updateTdk(self, self.$ssrContext);
}

function clientUpdateTdk(self: any) {
  updateTdk(self, typeof window === 'undefined' ? {} : window.document);
}

export default (TARGET_NODE ? serverUpdateTdk : clientUpdateTdk);
