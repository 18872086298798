










/* tslint:disable:no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
import {routerMap} from '@/configs'

import BarBlock from '@/components/widget/bar-block.vue'
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'NavBar',
  components: { BarBlock },
})
export default class NavBar extends Vue {
  @Prop({default: () => [{name: '首页', link: routerMap[Object.keys(routerMap)[0]].path}]}) private preNav!: any[];
  @Prop({default: () => []}) private navs!: any[];
  get getNavs(): any[] {
    // const df: any = [{name: '首页', link: routerMap[Object.keys(routerMap)[0]].path}]
    return this.preNav.concat(this.navs);
  }
}
