































/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import ImgBlock from "@/components/widget/img-block.vue";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "CommentBlock",
  components: { ImgBlock }
})
export default class CommentBlock extends Vue {
  @Prop() private info!: object;
  private evaluate: any = {};
  get getInfo(): any {
    let info: any = this.info;
    const evaluate = info.evaluate;
    evaluate.imgsrc = evaluate.user_icon;
    evaluate.content_id = evaluate.id; // content_id需要用来进入评论详情页
    this.evaluate = evaluate; // 记录评论内容
    info = Object.assign({}, info, {
      imgsrc: info.icon,
      btype: "comment"
    });
    return info;
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    self.$emit("clickBlock", self.getInfo, type, item);
  }
}
