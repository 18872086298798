






























/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import InstallBtn from "@/components/InstallBtn.vue";
import ImgBlock from "@/components/widget/img-block.vue";

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "AppBlockNormal",
  components: { InstallBtn, ImgBlock }
})
export default class AppBlockNormal extends Vue {
  @Prop({ default: true }) private download!: boolean;
  @Prop() private info!: any;
  @Prop() private source!: any;
  @Prop() private position!: any;
  @Prop({ default: true }) private lazyImg!: boolean; // 当前块的图片是否懒加载
  get getInfo(): any {
    const self = this
    const info: any = Object.assign({}, self.info, {
      imgsrc: self.info.icon || self.info.img_url,
      btype: 'game'
    });
    if (self.position) {
      info.px = self.position.px
      info.py = self.position.py
    }
    return info;
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    if (self.getInfo.downloadUrl) {
      return
    }
    self.$emit("clickBlock", self.getInfo, type, item);
  }
}
