
























/* tslint:disable:no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
import InstallBtn from '@/components/InstallBtn.vue';
import coupon from '@/components/widget/coupon.vue';
import ImgBlock from '@/components/widget/img-block.vue';
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'AppBlockCoupon',
  components: { InstallBtn, coupon, ImgBlock },
})
export default class AppBlockCoupon extends Vue {
  @Prop() private info!: any[];
  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      title: info.name,
      desc: info.recommend_desc,
      download: info.size ? true : false, // 是否显示下载
      imgsrc: info.icon || info.img_url,
      btype: 'game'
    });
    return info
  }
  get getCoupons(): any {
    const self: any = this;
    const coupons: any[] = self.getInfo.coupons || [];
    return coupons.map((it, idx) => {
        it.idx = idx;
        return it;
    })
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    self.$emit("clickBlock", self.getInfo, type, item);
  }
}
