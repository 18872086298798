








/* tslint:disable:no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueLazyload from 'vue-lazyload';
import { lazyImg } from '@/configs'

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: lazyImg,
  loading: require('assets/images/dot.png'),
  attempt: 2,
});

interface IImgInfo {
    imgsrc: string;
    imglink?: string;
    imgalt?: string;
    imgsource?: string;    // 来源，埋点需要使用
}
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'ImgBlock',
})
export default class ImgBlock extends Vue {
  @Prop() private info!: IImgInfo;
  @Prop() private lazyImg!: boolean;
}
