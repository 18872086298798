





















/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import ImgBlock from "@/components/widget/img-block.vue";
import MoreText from "@/components/widget/moreText.vue";
import Stars from "@/components/widget/stars.vue";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "Comment",
  components: { ImgBlock, MoreText, Stars }
})
export default class Comment extends Vue {
  @Prop() private info!: any;
  @Prop({default: true}) private showLikes!: boolean;

  get getInfo(): any {
    let info: any = this.info || {};
    info = Object.assign({}, info, {
      imgsrc: info.user_icon
    })
    return info;
  }
  get getPlan(): string {
    const info: any = this.info;
    return info.plan || "0%";
  }
  private tread(info: any): void {
    this.$store.dispatch('fetchCommentTread', {context: this, params: { id: info.id }}).then((data: any) => {
      console.log(data)
    })
  }
  private like(info: any): void {
    this.$store.dispatch('fetchCommentLike', {context: this, params: { id: info.id }}).then((data: any) => {
      console.log(data)
    })
  }
  private fatTime(time: string): string {
    const self: any = this;
    return self.utils.formatTime(new Date(time), 'M月D日')
  }
}
