








/* tslint:disable:no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'BarBlock',
})
export default class BarBlock extends Vue {
  @Prop() private info!: any;
}
