

































/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import InstallBtn from "@/components/InstallBtn.vue";
import ImgBlock from "@/components/widget/img-block.vue";
import Stars from "@/components/widget/stars.vue";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "AppBlockGrade",
  components: { InstallBtn, ImgBlock, Stars }
})
export default class AppBlockGrade extends Vue {
  @Prop({ default: true }) private download!: boolean;
  @Prop() private info!: any;
  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      download: info.size ? true : false,
      imgsrc: info.icon,
      btype: 'game'
    });
    return info;
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    self.$emit("clickBlock", self.getInfo, type, item);
  }
}
