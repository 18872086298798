







/* tslint:disable:no-console */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import utils from '@/utils'

// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'MoreText',
})
export default class ImgBlock extends Vue {
  @Prop({default: ''}) private text!: string;
  @Prop({default: 2}) private line!: number;
  private show: boolean = false;
  private haveMore: boolean = false;
  private showMore: boolean = false;
  get getStyle(): string {
    return this.haveMore && !this.showMore ? `-webkit-line-clamp: ${this.line}` : '';
  }
  private targetMore(): void {
    this.showMore = !this.showMore;
  }
  private mounted(): void {
    if (this.text) {
      this.onChangeText()
    }
  }
  @Watch('text')
  private onChangeText(val?: any, old?: any) {
    this.$nextTick(() => {
      const rf: any = this.$refs.texts;
      const doc: any = typeof document !== 'undefined' ? document : {};
      const showHei = (this.line * 56 / 108 * parseFloat(doc.documentElement.style.fontSize)).toFixed(2);
      if (rf.offsetHeight > showHei) {
        this.haveMore = true;
      }
      this.show = true; // 显示内容
    })
  }
}
