











/* tslint:disable:no-console */
import { Component, Vue, Prop } from "vue-property-decorator";

import { blockMap } from "@/configs";
import Swiper from "@/components/block/Swiper.vue";
import BlockBox from "@/components/common/Block-box.vue";
import AppBlockNormal from "@/components/block/App-block-normal.vue";
import ImgBlockNormal from "@/components/block/Img-block-normal.vue";
import AppBlockSmall from "@/components/block/App-block-small.vue";
import AppBlockCard from "@/components/block/App-block-card.vue";
import AppBlockCoupon from "@/components/block/App-block-coupon.vue";
import AppBlockCardSmall from "@/components/block/App-block-card-small.vue";
import AppBlockKey from "@/components/block/App-block-key.vue";
import NewsList from "@/components/block/News-list.vue";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "IndexTemplate",
  components: {
    Swiper,
    BlockBox,
    AppBlockNormal,
    ImgBlockNormal,
    AppBlockSmall,
    AppBlockCard,
    AppBlockCoupon,
    AppBlockCardSmall,
    AppBlockKey,
    NewsList
  },
})
export default class IndexTemplate extends Vue {
  // 初始数据可以直接声明为实例的属性
  @Prop() private block!: object;
  @Prop({default: 0}) private py!: number;
  private layout: string = '1-1';
  private moreLink: string = '';
  private componentName: string = '';

  get getBlock(): string {
    const self: any = this;
    const BlockMap: any = blockMap;
    const Block: any = self.block;
    for (const key of Object.keys(BlockMap)) {
      const item: any[] = BlockMap[key];
      for (const bk of item) {
        if (bk.type === Block.type) {
          self.layout = bk.layout;
          // 把配置信息插入到块结构
          Block.moreLink = bk.moreLink;
          Block.blockLink = bk.blockLink;
          Block.ctype = (bk.blockLink || '').replace(/(list|detail)/ig, ''); // 获取当前的内容类型
          Block.block_id = Block.id;
          Block.block_name = Block.name;
          Block.block_type = Block.type;
          Block.py = self.py;
          // 取得当前的componentName
          console.log(key);
          self.componentName = key;
          break;
        }
      }
    }
    return Block;
  }
  get getSource(): any {
    const Block: any = this.block;
    return Block;
  }
}
