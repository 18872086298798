

















































/* tslint:disable:no-console */
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { toRem } from "@/utils";
import mixins from "@/mixin/index.ts";
import LoadMore from "@/components/base/loadmore.vue";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "BlockBox",
  components: {LoadMore},
  mixins: [mixins]
})
export default class BlockBox extends Vue {
  @Prop() private title!: string; // 标题
  @Prop() private moreText!: string; // 更多文本
  @Prop() private moreLink!: string; // 更多地址
  @Prop({ default: "1-1" }) private layout!: string; // 排列规则
  @Prop() private block!: any; // 块数据
  @Prop() private padding!: any[]; // 边距
  @Prop({ default: "" }) private addClass!: string; // class
  @Prop({ default: 0 }) private py!: number | string; // 整块的y位置
  @Prop() private moreClick!: any; // 自定义点击更多
  @Prop({default: false}) private noWatch!: boolean; // 自定义点击更多

  @Prop({default: false}) private loadMore!: boolean; // 是否加载更多
  @Prop({default: null}) private loadMoreHandler!: any; // 自定义加载更多事件
  @Prop({default: ''}) private loadStatus!: string; // 自定义点击更多

  /* private $refs!: {
    div: HTMLDivElement, // html元素
    cComponent: eComponent // Typescript可以正确提示出组件中的方法和属性
  } */
  private timeSign: number = 0; // 时间标记，用来刷新数据
  private tmpChildren: any[] = [];

  get layouts(): any {
    const layouts: any[] = (this.layout || "1-1").split("-");
    return {
      x: layouts[0] || 1,
      y: layouts[1] || 1
    };
  }
  get getList(): any[] {
    const self: any = this;
    let list: any[] = [];
    // 数据 添加额外的数据，用来埋点上报
    // [self.block] 代表当前块数据就是实际的渲染数据，并且只有一条
    if (self.block instanceof Array) {
      list = self.block;
    } else if (self.block.data_source) {  // 每个数据都一定会有来源
      list = self.block.data || [self.block] || [];
    }
    self.timeSign = new Date().getTime();
    return list.map((it: any, idx: number) => {
      it.px = idx;
      it.py = self.py;
      it.data_source = it.data_source || self.block.data_source; // 数据来源
      it.rank_id = self.block.rank_id; // 所在榜单ID
      it.block_id = self.block.id || self.block.block_id; // 榜单块的id
      it.block_name = self.block.name || self.block.block_name; // 榜单块的名字
      it.block_type = self.block.type || self.block.block_type; // 榜单块的类型
      it.blockLink = self.block.blockLink; // 配置的块链接
      it.moreLink = self.block.moreLink; // 配置更多的链接
      it.ctype = self.block.ctype; // 当前的内容类型
      return it;
    });
  }
  get getTotalList(): number {
    const list: any = this.getList || [];
    const layouts: any = this.layouts;
    if (layouts.x === "n" || layouts.y === "n") {
      return list;
    } else {
      const len = Number(layouts.x) * Number(layouts.y) || 0;
      return list.slice(0, len);
    }
  }
  get getWarpStyle(): string {
    if (!this.padding) {
      return "";
    }
    const len: number = this.padding.length;
    let padding: any[] = Array(4).fill(0);
    switch (len) {
      case 4:
        padding = this.padding;
        break;
      case 1:
        padding.fill(this.padding[0]);
        break;
      case 2:
        padding = [
          this.padding[0],
          this.padding[1],
          this.padding[0],
          this.padding[1]
        ];
        break;
      case 3:
        padding = [
          this.padding[0],
          this.padding[1],
          this.padding[2],
          this.padding[1]
        ];
        break;
      default:
        break;
    }
    return `padding: ${toRem(padding[0])} ${toRem(padding[1])} ${toRem(
      padding[2]
    )} ${toRem(padding[3])}`;
  }
  get getListStyle(): string {
    const layouts: any = this.layouts;
    return layouts.y === "n"
      ? `width: auto;text-align: left;white-space: nowrap;flex-wrap: nowrap;`
      : `width: 100%;text-align: left;`;  // overflow: hidden;
    // justify-content: space-between;
  }
  get getSrcollStyle(): string {
    const layouts: any = this.layouts;
    return layouts.y === "n"
      ? `overflow-x: auto;`
      : `overflow: visible;`;  // overflow: hidden;
    // justify-content: space-between;
  }
  get getBoxStyle(): any {
    const layouts: any = this.layouts;
    return (idx: any): string => {
      const children: any = this.tmpChildren[idx];
      const childrenWidth = (children && children._props.width) || "10px";
      // return `min-width: ${childrenWidth};width: auto;`;
      // n列的时候，使用默认的margin
      if (layouts.y === "n") {
        return `min-width: ${childrenWidth};width: auto;`;
      } else {
        return Number(layouts.y) === 1 ? `width: 100%;` : `width: auto;`; // `width: ${(1 / Number(this.layouts.y) * 100).toFixed(4)}%;`;
      }
    };
  }
  /* private mounted(): void {
    const layouts: any[] = (this.layout || '1-1').split('-');
    this.layouts = {
        x: layouts[0] || 1,
        y: layouts[1] || 1,
    };
  } */
  private updated(): void {
    this.tmpChildren = this.$children;
  }
  private listTouchmove(event: any): boolean {
    const layouts: any = this.layouts;
    if (layouts.y === "n") {
      event.stopPropagation();
    }
    return true;
  }
  private clickGoMore(data?: any, type?: string): void {
    const self: any = this
    if (typeof self.moreClick === 'function') {
      self.moreClick(self, data, type);
    } else {
      self.clickBlock(data, type)
    }
  }
}
