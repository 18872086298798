var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"BlockBox clearfix",class:_vm.addClass,attrs:{"data-key":_vm.timeSign}},[_vm._t("title",[(!!_vm.title)?_c('div',{staticClass:"block_head"},[_c('div',{staticClass:"block_head-box"},[_c('p',{staticClass:"block_head-title"},[_vm._v(_vm._s(_vm.title))]),(_vm.block.more || _vm.moreText)?_c('a',{staticClass:"block_head-more",attrs:{"href":"javascript:;"},domProps:{"textContent":_vm._s(_vm.moreText ? _vm.moreText : '更多')},on:{"click":function($event){return _vm.clickGoMore(_vm.block, _vm.moreLink ? _vm.moreLink : 'more')}}}):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"block_box",style:(_vm.getWarpStyle)},[_c('LoadMore',{ref:"loadmore",attrs:{"loadMore":_vm.loadMore,"distance":2,"onInfinite":_vm.loadMoreHandler,"loadStatus":_vm.loadStatus}},[_c('div',{staticClass:"roll_box clearfix",style:(_vm.getSrcollStyle)},[_c('ul',{staticClass:"block_list clearfix",style:(_vm.getListStyle),attrs:{"layout":_vm.layout},on:{"touchmove":_vm.listTouchmove}},_vm._l((_vm.getTotalList),function(item,index){return _c('li',{directives:[{name:"exposure",rawName:"v-exposure",value:(_vm.exposureData(item, _vm.noWatch)),expression:"exposureData(item, noWatch)"}],key:index,staticClass:"block_li clearfix",class:[
            { first: index === 0 },
            { last: index == _vm.getTotalList.length - 1 },
            { 'l-first': index % _vm.layouts.y == 0 },
            { 'l-last': index % _vm.layouts.y == _vm.layouts.y - 1 },
            { _y: _vm.layouts.y == 1 },
            { _x: _vm.layouts.x == 1 }
          ],style:(_vm.getBoxStyle(index))},[_vm._t("default",null,{"data":item,"clickBlock":_vm.clickBlock})],2)}),0)])])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }