














/* tslint:disable:no-console */
import { Component, Prop, Vue } from 'vue-property-decorator';
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: 'Mgift',
})
export default class Mgift extends Vue {
  @Prop() private info!: any[];
  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      title: info.title || info.name
    });
    return info;
  }
  get getPlan(): string {
      const info: any = this.info;
      return info.plan || '0%';
  }
  private showGift() {
      const self: any = this;
      // self.$toast.show('稍等，还在做')
      self.$dialog.tips({title: '', content: '', data: self.getInfo})
  }
}
