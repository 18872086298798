


























/* tslint:disable:no-console */
import { Component, Prop, Vue } from "vue-property-decorator";
import InstallBtn from "@/components/InstallBtn.vue";
import ImgBlock from "@/components/widget/img-block.vue";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "AppBlockKey",
  components: { InstallBtn, ImgBlock }
})
export default class AppBlockKey extends Vue {
  @Prop() private info!: any[];
  get getInfo(): any {
    let info: any = this.info;
    info = Object.assign({}, info, {
      title: info.name,
      grade: info.avg_score,
      key: info.recommend_desc,
      imgsrc: info.icon,
      btype: 'game'
    });
    return info;
  }
  // 传递子模块的数据
  private clickValidBlock(type?: string, item?: any): void {
    const self: any = this;
    self.$emit("clickBlock", self.getInfo, type, item);
  }
}
